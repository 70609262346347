import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
export var dateFormat = 'YYYY-MM-DD';
export var timeFormat = 'HH:mm';
export var timeWithSecondsFormat = 'HH:mm:ss';
export var dateTimeFormat = "".concat(dateFormat, " ").concat(timeFormat);
export var dateTimeWithSecondsFormat = "".concat(dateFormat, " ").concat(timeWithSecondsFormat);
/**
 * Returns a string in the format HH[h]:mm[m] (eg: 15h30m) as the duration of a time interval
 *
 * @returns {string}
 */ export var getTimeDuration = function(startTime, endTime) {
    var inputTimeFormat = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : timeFormat;
    if (!startTime || !endTime) {
        return '';
    }
    var dayjsStartTime = dayjs(startTime, inputTimeFormat);
    var dayjsEndTime = dayjs(endTime, inputTimeFormat);
    if (dayjsStartTime >= dayjsEndTime) {
        dayjsEndTime.add(1, 'days');
    }
    var duration = dayjs.duration(dayjsEndTime.diff(dayjsStartTime));
    var hours = Math.floor(duration.asHours());
    var minutes = Math.floor(duration.asMinutes()) % 60;
    var formattedDuration = '';
    if (hours !== 0) {
        formattedDuration = "".concat(hours, "h");
    }
    if (minutes !== 0) {
        formattedDuration += " ".concat(minutes, "m");
    }
    return formattedDuration;
};
/**
 * Return an array of AntDesign DefaultOptionType containing all the times for a TimePicker
 */ export var getTimeOptionsForTimePicker = function() {
    var minutesStep = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 15, startTime = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : null, endTime = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : null;
    var options = [];
    var dayjsStartTime = dayjs(startTime || '00:00', timeFormat);
    var dayjsEndTime = endTime ? dayjs(endTime || '23:45', timeFormat).add(1, 'minutes') : dayjs(dayjsStartTime).add(1, 'days');
    if (dayjsEndTime < dayjsStartTime) {
        dayjsEndTime = dayjsEndTime.add(1, 'days');
    }
    while(dayjsStartTime < dayjsEndTime){
        var label = void 0;
        var formattedTime = dayjsStartTime.format(timeFormat);
        if (startTime) {
            label = "".concat(formattedTime, " (").concat(getTimeDuration(startTime, formattedTime), ")");
        }
        options.push({
            value: formattedTime,
            label: label
        });
        dayjsStartTime = dayjsStartTime.add(minutesStep, 'minutes');
    }
    if (startTime && !endTime) {
        // move the first option to the bottom
        options.push(options.shift());
    } else {
        options.shift();
    }
    return options;
};
export var formatDate = function formatDate(date) {
    var format = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : dateFormat;
    if (!date) {
        return null;
    }
    return dayjs(date).format(format);
};
export var getDateRangePresets = function() {
    var lastWeek = dayjs().subtract(1, 'weeks');
    var nextWeek = dayjs().add(1, 'weeks');
    var lastMonth = dayjs().subtract(1, 'months');
    var nextMonth = dayjs().add(1, 'months');
    return [
        {
            label: ts('Today'),
            value: [
                dayjs().startOf('day'),
                dayjs().endOf('day')
            ]
        },
        {
            label: ts('Tomorrow'),
            value: [
                dayjs().add(1, 'day').startOf('day'),
                dayjs().add(1, 'day').endOf('day')
            ]
        },
        {
            label: ts('Last week'),
            value: [
                lastWeek.startOf('isoWeek'),
                lastWeek.clone().endOf('isoWeek')
            ]
        },
        {
            label: ts('This week'),
            value: [
                dayjs().startOf('isoWeek'),
                dayjs().endOf('isoWeek')
            ]
        },
        {
            label: ts('Next week'),
            value: [
                nextWeek.startOf('isoWeek'),
                nextWeek.clone().endOf('isoWeek')
            ]
        },
        {
            label: ts('Last month'),
            value: [
                lastMonth.startOf('month'),
                lastMonth.clone().endOf('month')
            ]
        },
        {
            label: ts('This month'),
            value: [
                dayjs().startOf('month'),
                dayjs().endOf('month')
            ]
        },
        {
            label: ts('Next month'),
            value: [
                nextMonth.startOf('month'),
                nextMonth.clone().endOf('month')
            ]
        }
    ];
};
export var getDayjsDateOrNull = function(value) {
    var format = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : dateFormat;
    if (!value) {
        return null;
    }
    if (!dayjs(value, format, true).isValid()) {
        throw Error('Invalid date "'.concat(value, '". Expected format is "').concat(format, '".'));
    }
    return dayjs(value, format);
};
export var getDayjsTimeOrNull = function(value) {
    var format = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : timeFormat;
    if (!value) {
        return null;
    }
    if (!dayjs(value, format, true).isValid()) {
        throw Error('Invalid time "'.concat(value, '". Expected format is "').concat(format, '".'));
    }
    return dayjs(value, format);
};
