export function getApp() {
    return window.location.pathname.split('/')[1].toLowerCase();
}
export function isAuthenticationApp() {
    return getApp() === 'auth';
}
export function isEmployeeApp() {
    return getApp() === 'employee';
}
export function isPlannerApp() {
    return getApp() === 'planner';
}
export function isRecruitingApp() {
    return getApp() === 'recruiting';
}
